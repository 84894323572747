import React from "react";
import "./styles.css";

const currentYear = new Date().getFullYear();

const Footer = ({ hitCount = {} }) => {
  const hits = hitCount.hits;
  const global = hitCount.global;
  const globalUMU = hitCount.globalUMU;

  return (
    <div className="main-footer">
      <div>
        &copy; 2014-{currentYear} Silverware Games, Inc. All rights reserved.
        <br />
        {hits !== undefined && global !== undefined && globalUMU !== undefined ? (
          <>
            Page: {hits} Global: {global} UMU: {globalUMU}
          </>
        ) : (
          "Could not connect to hit server."
        )}
      </div>
    </div>
  );
};

export default Footer;
