import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./style.css";

function extractImageName(filePath) {
  // Split the file path to get the last part
  const parts = filePath.split("/");
  const encodedName = parts[parts.length - 1];

  // Decode the encoded name
  const decodedName = decodeURIComponent(encodedName);

  // Extract the image name without the date part and remove the .jpg extension
  const nameParts = decodedName.split(" ");
  const imageNameWithExtension = nameParts.slice(1).join(" ");
  const imageName = imageNameWithExtension.substring(0, imageNameWithExtension.lastIndexOf('.'));

  return imageName;
}

function extractAuthor(filename) {
  const parts = filename.split(/[/.]/);
  if (parts.length > 7) {
    if (parts[7] === "M") {
      return "michaelplzno";
    }
    if (parts[7] === "L") {
      return "Lily Jupiter";
    }
  }

  return "unknown";
}

function extractDate(filename) {
  // Extract the date part using regex
  const parts = filename.split(/[/.]/);

  if (parts.length > 9) {
    const day = parts[9];
    const month = parts[8];
    const year = "20" + parts[3]; // Assuming the year is in the format YY

    // Create a date object
    const dateObj = new Date(`${year}-${month}-${day}`);

    // Format the date as "Month Day, Year"
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    if (formattedDate === "Invalid Date") {
      const day2 = parts[5];
      const month2 = parts[4];
      const year2 = "20" + parts[3]; // Assuming the year is in the format YY

      const dateObj2 = new Date(`${year2}-${month2}-${day2}`);
      const formattedDate2 = dateObj2.toLocaleDateString("en-US", options);
      return formattedDate2;
    }
    return formattedDate;
  } else {
    console.log("Date not found in the filename");
  }

  return null;
}

const Image = () => {
  const { site, id, index } = useParams();
  const navigate = useNavigate();
  const [imageLocation, setImageLocation] = useState(null);
  const [title, setTitle] = useState("");
  const [displayDate, setDisplayDate] = useState(null);
  const [author, setAuthor] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(parseInt(index));
  const [imageCount, setImageCount] = useState(1);

  const handlePrevious = () => {
    let next = currentIndex - 1;
    if (next < 0) {
      next += imageCount;
    }

    setCurrentIndex(next);
    navigate(`/${site}/${id}/${next}`);
  };

  const handleNext = () => {
    let next = currentIndex + 1;
    if (next >= imageCount) {
      next -= imageCount;
    }

    setCurrentIndex(next);
    navigate(`/${site}/${id}/${next}`);
  };

  useEffect(() => {
    async function fetchImage() {
      try {
        const listModule = await import(`../../config/${site}/data.js`);
        const { blogList } = listModule;
        let blog = blogList.find((blog) => blog.id === parseInt(id));
        if (blog && blog.images) {
          setImageCount(blog.images.length);
        }
        if (blog && blog.images && blog.images.length > index) {
          setImageLocation(blog.images[parseInt(index)]);
          setTitle(extractImageName(blog.images[parseInt(index)]));
          setDisplayDate(extractDate(blog.images[parseInt(index)]));
          setAuthor(extractAuthor(blog.images[parseInt(index)]));
        } else {
          setImageLocation("/assets/images/13525-empty.gif");
          setDisplayDate(null);
          setAuthor(null);
          setTitle("Image not found");
          setImageCount(1);
        }
      } catch (error) {
        console.error("Error loading list:", error);
        setImageLocation("/assets/images/13525-empty.gif");
        setTitle("Error loading image");
        setDisplayDate(null);
        setAuthor(null);
        setImageCount(1);
      }
    }

    fetchImage();
  }, [site, id, index]);

  return (
    <>
      <Link className="blog-goBack" to={`/${site}/${id}`}>
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
      <div className="header-container">
        {displayDate && author ? (
          <p className="blog-date">
            {displayDate} by {author}
          </p>
        ) : null}
        <h1>{title}</h1>
      </div>
      <div className="image-container">
        {imageLocation && (
          <div className="image-wrapper">
            <img
              src={imageLocation}
              alt={title}
              onClick={handleNext}
              className="main-image"
            />
          </div>
        )}
        <div className="navigation-buttons">
          <button onClick={handlePrevious} className="nav-button">
            ←
          </button>
          <button onClick={handleNext} className="nav-button">
            →
          </button>
        </div>
      </div>
    </>
  );
};

export default Image;
