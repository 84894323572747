import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Image from "./pages/Image";
import Footer from "./components/common/Footer";

const App = () => {
  const [hitCount, setHitCount] = useState({});
  const [site, setSite] = useState("unset");
  const [id, setId] = useState("unset");
  const [index, setIndex] = useState("unset");
  const [userID] = useState(() => {
    const storedUserID = document.cookie.split('; ').find(row => row.startsWith('userID='));
    if (storedUserID) {
      return storedUserID.split('=')[1];
    } else {
      const newUserID = Date.now().toString();
      document.cookie = `userID=${newUserID}; path=/; max-age=31536000`; // 1 year
      return newUserID;
    }
  });
  const location = useLocation();

  const fetchHitCount = async (site, id, index, userID) => {
    const baseUrl =
      window.location.hostname === "localhost"
        ? "http://localhost:3001"
        : "https://silverwaregames.io";
    const params = new URLSearchParams();
    if (site) params.append("site", site);
    if (id) params.append("id", id);
    if (index) params.append("index", index);
    if (userID) params.append("userID", userID);
    try {
      const response = await fetch(`${baseUrl}/track?${params.toString()}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHitCount(data);
    } catch (error) {
      console.error("Error fetching hit count:", error);
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const newSite = pathParts[0] || null;
    const newId = pathParts[1] || null;
    const newIndex = pathParts[2] || null;

    if (newSite !== site || newId !== id || newIndex !== index) {
      setSite(newSite);
      setId(newId);
      setIndex(newIndex);
      fetchHitCount(newSite, newId, newIndex, userID);
    }
  }, [id, index, location.pathname, site, userID]); // Fetch hit count only if URL changes

  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:site" element={<Home />} />
        <Route path="/:site/:id" element={<Blog />} />
        <Route path="/:site/:id/:index" element={<Image />} />
      </Routes>
      <Footer hitCount={hitCount} />
    </div>
  );
};

export default App;
