export const recentPost = {
  "id": 15,
  "site": "swg",
  "title": "Speedway Heroes™ on PC",
  "category": "game",
  "subCategory": [
    "kart",
    "racing",
    "launch"
  ],
  "description": "Classic kart racing physics with a new randomized track each race. Play on-line with friends, against bots, or try to survive the single player race to the DMV. Sign up now for your free in game licence plate. The lowest numbers are going fast!",
  "fullText": "<p>Classic kart racing physics with a new randomized track each race. Play on-line with friends, against bots, or try to survive the single player race to the DMV. Sign up now for your free in game licence plate. The lowest numbers are going fast!</p><h3>New track Each Race</h3><figure><img src=\"/assets/images/trackgen.gif\" alt=\"Procedural Track Generation\" /><figcaption>Procedural Track Generation</figcaption></figure><p>Tracks are procedurally generated on the fly by calculating a curvy path and filling it with pre-designed segments chosen at random. The best racers will have to adapt quickly to learn the course and take advantage of short-cuts to win.</p><h3>Meet the Heroes</h3><figure><img src=\"/assets/images/meet_the_heroes.webp\" alt=\"Be a Horse\" /><figcaption>Be a Horse.</figcaption></figure><p>Choose your \"kart\" from a roster of wacky heroes with different abilities. Fire the car's laser, teleport through walls in the UFO, or jump over obstacles with the horse. The faster you clear checkpoints the more item power you have to use on your rivals.</p><h3>Latest Update: 7/1/15 - Check Points!</h3><figure><img src=\"/assets/images/latest_update.gif\" alt=\"Latest Update\" /><figcaption>Latest Update.</figcaption></figure><p>The latest version is 0.3.05 and it has:</p><ul><li>Mini-Map.</li><li>Multiple Branching Paths.</li><li>Grab some coins!</li><li>Check in at Toll Booths.</li><li>See how fast you are going at each Check Point.</li><li>Get bonuses to speed and acceleration at toll booths.</li></ul>",
  "authorName": "Michael Silverman",
  "authorLink": "https://michaelplzno.com",
  "authorAvatar": "/assets/images/author.jpg",
  "createdAt": "December 13, 2024",
  "cover": "/assets/images/SpeedwayHeroesGameplay.gif",
  "ctaButtons": [
    {
      "platformLogo": "/assets/images/windows-logo.png",
      "titleText": "Get it on PC Now",
      "priceTag": "FREE ALPHA TEST!",
      "appIcon": "/assets/images/SpeedwayHeroesIcon.png",
      "linkTo": "/assets/downloads/SpeedwayHeroes_0.3.05.zip"
    }
  ]
};